<template>
    <div class="confirm-page">
        <div v-if="scope" class="confirm-page__content">
            <InstanceInfo :name-disabled="true" :info-list="infoList" :name="scope.name"
                          :date="lastUpdateDescription(scope.last_activity)"/>

            <div class="confirm-page__title">Send Contract & Notify All Parties 2</div>

            <div class="confirm-page__actions">
                <div class="confirm-page__action-block">
                    <span class="confirm-page__action-text">
                        Congratulations! You are ready to send this contract to
                        <strong>{{ scope.external_company.name }}</strong>.
                        When sent, <strong>{{ scope.external_signer.full_name }}</strong>
                        will be notified immediately by email.
                    </span>
                    <CButton color="primary" :disabled="loading || sent" @click="sendPackage">Send Package</CButton>
                </div>

                <div class="confirm-page__action-block">
                    <span class="confirm-page__action-text">
                        You can also download the package below to keep a record of the primary contract and the
                        associated requirements.
                    </span>
                    <LoadingButton color="primary" variant="outline" :loading="loadingDownload"
                                   @click="downloadPackage">
                        Download Package
                    </LoadingButton>
                </div>
            </div>

            <div class="confirm-page__title">Schedule & Due Dates</div>

            <div>
                <span class="confirm-page__action-text">
                    Flowdown tasks and documents will be sent in accordance with your template settings at a later date.
                </span>
            </div>

            <div class="confirm-page__table">
                <ScheduleDatesTable :items="tasks"/>
            </div>

            <StickyFooter>
                <CButton color="primary" variant="outline" @click="stepDone">Done</CButton>
            </StickyFooter>
        </div>

        <div v-else class="confirm-page__loading">
            <CSpinner color="primary"/>
        </div>
    </div>
</template>

<script>
import InstanceInfo from '@/components/Forms/Partials/InstanceInfo.vue';
import ScheduleDatesTable from '@/components/Tables/ScheduleDatesTable.vue';
import StickyFooter from '@/components/Forms/Partials/StickyFooter.vue';
import Scopes from "@/api/v2/endpoints/Scopes";
import recordActivity from "@/mixin/recordActivity";
import apiErrorHandler from "@/mixin/apiErrorHandler";
import pushCommitmentHelper from "@/mixin/scopes/pushCommitmentHelper";
import LoadingButton from "@/components/LoadingButton.vue";

export default {
    name: 'ConfirmSend',
    components: {LoadingButton, StickyFooter, ScheduleDatesTable, InstanceInfo},
    inject: ['downloadToast', 'toast'],
    mixins: [recordActivity, apiErrorHandler, pushCommitmentHelper],
    data() {
        return {
            tasks: [],
            scope: null,
            loading: false,
            loadingDownload: false,
            sent: false,
        };
    },
    computed: {
        infoList() {
            return [
                {label: 'Selected package', value: this.scope.template_folder.name},
                {label: 'Company Name (Sub)', value: this.scope.external_company.name},
                {label: 'Default Signer Name (Sub)', value: this.scope.external_signer.full_name},
            ];
        },
    },
    created() {
        this.loadScope();
    },
    methods: {
        loadScope() {
            Scopes
                .show(this.$route.params.scopeId, {
                    scope_data: 'id,name,status,step',
                    with_external_company: 'name',
                    with_external_signer: 'full_name',
                    with_template_folder: 'name',
                    with_unassigned_tasks_amount: 1,
                    with_last_activity: 1,
                    with_primary_unassigned_tasks: 'id,name,file_name,is_main,required_action,start_date,due_date,is_indefinite',
                    with_template: 'id',
                    with_upload: 'all'
                })
                .then(async response => {
                    await this.checkPushCommitmentStep(response.data.step);
                    this.scope = response.data;
                    this.tasks = response.data.primary_unassigned_tasks;
                    this.$emit('lastAvailableStep', this.scope.step)
                });
        },
        sendPackage() {
            this.toggleLoading();

            Scopes
                .pushCommitmentStepFour(this.scope.id)
                .then(() => {
                    this.sent = true;
                    this.toast('success', 'Congratulations! Contract has been successfully sent.');
                    this.$emit('lastAvailableStep', this.scope.step + 1);
                })
                .catch(response => this.handleApiError(response))
                .finally(() => this.toggleLoading());
        },
        stepDone() {
            const data = {
                name: 'ProjectTasks',
                id: this.$route.params.id,
            };

            this.$router.push(data);
        },
        downloadPackage() {
            this.toggleLoadingDownload();

            const task_ids = this.tasks.map(task => task.id);

            Scopes
                .downloadPackage(this.scope.id, {task_ids})
                .then(response => this.downloadToast.open(`download.${response.token}`, this.scope.name))
                .finally(() => this.toggleLoadingDownload());
        },
        toggleLoadingDownload() {
            this.loadingDownload = !this.loadingDownload;
        },
        toggleLoading() {
            this.loading = !this.loading;
        }
    },
};
</script>

<style scoped lang="scss">
.confirm-page {
    background: white;
    border-radius: 0 0 8px 8px;
    padding: 24px;

    &__loading {
        display: flex;
        justify-content: center;
        padding: 150px 0;
    }

    &__content {
        gap: 24px;
        display: flex;
        flex-direction: column;
    }

    &__title {
        font-size: 20px;
        font-weight: 600;
        color: #1c262f;
        margin-bottom: 0;
    }

    &__actions {
        display: flex;
        max-width: 1100px;
    }

    &__action-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        flex: 1;
        padding-right: 25px;
    }

    &__action-text {
        min-height: 90px;
        color: #677a89;
        font-size: 16px;
    }
}
</style>
